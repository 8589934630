import React from "react"
import Layout from "../../components/_layout"
import { Box, Flex, Text } from "rebass"

const PrivacyForYou = () => {
  return (
    <Layout forceOpaqueHeader>
      <Flex
        sx={{ paddingX: [5, 10] }}
        paddingY={"10rem"}
        justifyContent={"center"}
      >
        <Box sx={{ flexGrow: 1 }} maxWidth={1280}>
          <Text mt={[3, 4]} fontSize={["13px"]}>
            Il presente documento è redatto da Semplice Media S.r.l. (di seguito
            “Semplice Media”), in qualità di titolare del trattamento dei dati
            personali derivanti dalla navigazione all’interno del presente sito
            o pagina web (di seguito il “Sito”). Si tratta di un’informativa
            resa in conformità alla vigente normativa in materia dei dati
            personali nel quadro del Regolamento UE 2016/679.
          </Text>
          <Text mt={[3, 4]} fontSize={["20px", "24px"]} fontWeight="700">
            Identità del Titolare del trattamento:
          </Text>
          <Text mt={[3, 4]} fontSize={["13px"]}>
            I dati così raccolti saranno trattati da Vodafone Italia S.p.A. che
            agisce in qualità di Titolare del trattamento per l’esecuzione del
            contatto telefonico da lei richiesto. Per l’esecuzione di tale
            contatto telefonico Vodafone Italia SpA ha nominato Responsabile del
            trattamento, ai sensi dell’art. 28 del Regolamento Privacy, 4U con
            sede legale in Via di Affogalasino nr. 105 - 00148 Roma, 4U ha
            nominato un Responsabile della Protezione dei Dati contattabile in
            qualsiasi momento al seguente indirizzo mail dpo@4uitalia.it Per la
            raccolta dei dati nel sito il Titolare del trattamento ha nominato
            la società Semplice media Srl nella qualità di Responsabile del
            trattamento dei dati personali. Il Responsabile del trattamento
            potrà a sua volta avvalersi del supporto di soggetti autorizzati
            appositamente nominati e istruiti. Il Responsabile Esterno ha
            facoltà di ricorrere ad altri soggetti per l’esecuzione di
            specifiche attività di trattamento per conto del Titolare, su tali
            altri soggetti sono imposti gli stessi obblighi contenuti nel
            contratto con il Responsabile.
          </Text>
          <Text mt={[3, 4]} fontSize={["20px", "24px"]} fontWeight="700">
            Tipo e fonte dei dati:
          </Text>
          <Text mt={[3, 4]} fontSize={["13px"]}>
            I dati personali trattati per l’esecuzione del contatto telefonico
            sono quelli da Lei volontariamente forniti in occasione della
            compilazione del modulo di richiesta di contatto e quelli che
            potranno essere acquisiti in futuro nella gestione delle attività
            connesse alla Sua richiesta. Vodafone Italia S.p.A. tratta solamente
            i dati personali strettamente pertinenti alla prestazione dei
            servizi richiesti dagli utenti – a seguito dell’accettazione della
            presente informativa – e funzionali sia alla formulazione di
            proposte contrattuali, redatte sulla base di tutti gli elementi di
            “personalizzazione” della tariffa/servizio richiesti, sia per
            consentire l’applicazione di condizioni contrattuali “ad personam”
            in funzione delle singole specificità dichiarate dal singolo utente.
            Eventuali siti web di terzi di cui si faccia riferimento e/o
            collegamento, anche tramite link, sono esclusiva responsabilità dei
            terzi soggetti che li gestiscono e sono pertanto sottoposti ad
            autonomi termini condizioni e privacy policy dei rispettivi
            fornitori.
          </Text>
          <Text mt={[3, 4]} fontSize={["20px", "24px"]} fontWeight="700">
            Finalità del trattamento:
          </Text>
          <Text mt={[3, 4]} fontSize={["13px"]}>
            I dati personali sono raccolti da Vodafone Italia S.p.A per le
            seguenti finalità:
            <ul style={{ listStyle: "disc", paddingLeft: "1rem" }}>
              {" "}
              <li>
                Consentire l’erogazione del Servizio richiesto (contatto
                telefonico);
              </li>{" "}
              <li>Rispondere a richieste di assistenza o di informazioni;</li>{" "}
              <li>
                Assolvere eventuali obblighi di legge, contabili e fiscali;
              </li>{" "}
            </ul>
          </Text>
          <Text mt={[3, 4]} fontSize={["20px", "24px"]} fontWeight="700">
            Base giuridica del trattamento:
          </Text>
          <Text mt={[3, 4]} fontSize={["13px"]}>
            I dati personali trattati da Vodafone Italia e sono raccolti sulla
            base giuridica del “consenso” per dare esecuzione alla Sua richiesta
            di adesione al servizio erogato e per il riscontro alle Sue
            richieste (art.6, comma 1, lett. b) del GDPR in relazione alle
            finalità 1, 2 e 3).
          </Text>
          <Text mt={[3, 4]} fontSize={["20px", "24px"]} fontWeight="700">
            Trasferimento dei dati:
          </Text>
          <Text mt={[3, 4]} fontSize={["13px"]}>
            I dati personali trattati da Vodafone Italia sono raccolti sulla
            base giuridica del “consenso” per dare esecuzione alla Sua richiesta
            di adesione al servizio erogato e per il riscontro alle Sue
            richieste (art.6, comma 1, lett. b) del GDPR in relazione alle
            finalità 1, 2 e 3).
          </Text>
          <Text mt={[3, 4]} fontSize={["20px", "24px"]} fontWeight="700">
            Conservazione dei dati:
          </Text>
          <Text mt={[3, 4]} fontSize={["13px"]}>
            I dati personali saranno trattati e conservati da 4U per il tempo
            strettamente necessario a conseguire gli scopi sopra descritti, per
            adempiere ad obblighi contrattuali, di legge e di regolamento fatti
            salvi i termini prescrizionali e di legge nel rispetto dei diritti e
            in ottemperanza degli obblighi conseguenti. Con riferimento ai dati
            raccolti per finalità commerciali di cui alla presente informativa,
            i tempi sono limitati a (4) quattro giorni.
          </Text>
          <Text mt={[3, 4]} fontSize={["20px", "24px"]} fontWeight="700">
            Modalità di trattamento dei dati:
          </Text>
          <Text mt={[3, 4]} fontSize={["13px"]}>
            Il trattamento dei dati potrà essere effettuato attraverso strumenti
            manuali, automatizzati, informatici o telematici atti a memorizzare,
            gestire e trasmettere i dati stessi.
          </Text>
          <Text mt={[3, 4]} fontSize={["20px", "24px"]} fontWeight="700">
            Diritti dell’interessato:
          </Text>
          <Text mt={[3, 4]} fontSize={["13px"]}>
            Con riferimento agli artt. 15 – diritto di accesso, 16 – diritto di
            rettifica, 17 – diritto alla cancellazione, 18 – diritto alla
            limitazione del trattamento, 20 – diritto alla portabilità, 21 –
            diritto di opposizione, 22 diritto di opposizione al processo
            decisionale automatizzato del GDPR 679/16, in qualità di
            Interessato, l’interessato può rivolgere le sue richieste,
            attraverso specifica comunicazione, a mezzo posta indirizzata a
            dpo@4uitalia.it L’interessato ha facoltà di proporre reclamo al
            Garante per la Protezione dei dati personali con le modalità
            descritte sul sito web istituzionale: www.garanteprivacy.it
          </Text>
          <Text mt={[3, 4]} fontSize={["20px", "24px"]} fontWeight="700">
            Revoca del consenso:
          </Text>
          <Text mt={[3, 4]} fontSize={["13px"]}>
            Con riferimento all’art. 7, par. 3 del GDPR 679/16, l’interessato
            può revocare in qualsiasi momento i consensi manifestati, senza
            pregiudicare la liceità del trattamento basata sul consenso prestato
            prima della revoca. La revoca del consenso deve essere comunicata
            con le modalità evidenziate nel paragrafo precedente.
            <footer
              style={{
                color: "#343741",
                borderTop: "1px solid #f1f5f9",
                marginTop: "2rem",
                padding: "10px 0",
              }}
            >
              {" "}
              <p className="text-gray-800">
                Semplice Media S.r.l. a socio unico
              </p>{" "}
              <p className="text-gray-800">
                Società soggetta a Direzione e Coordinamento di Innova Semplice
                S.P.A.
              </p>{" "}
              <p className="text-gray-800">
                Sede legale Via Medina 40 – 80133 Napoli - Sede Operativa Via
                Amsterdam 125 – 00144 Roma
              </p>{" "}
              <p className="text-gray-800">
                Tel. 0810103507 – Email: amministrazione@semplicemedia.it
              </p>{" "}
              <p className="text-gray-800">
                Capitale sociale euro 100.000,00 – Partita Iva e Iscrizione
                Registro delle Imprese di Napoli 08542331213, n. REA 966344
              </p>{" "}
            </footer>
          </Text>
        </Box>
      </Flex>
    </Layout>
  )
}

export default PrivacyForYou
